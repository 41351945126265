import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.logos-carousel-block');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.logos-carousel-block__carousel'),
        {
          loop: true,
          slidesPerView: 2,
          spaceBetween: 15,
          centerInsufficientSlides: true,

          breakpoints: {
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1080: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          },

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },
        }
      );
    });
  }
});
